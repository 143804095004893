.app {
  text-align: center;
  font-size: 1.5rem;
}

.app-header {
  margin-top: -5rem;
  z-index: 10;
}

.container {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-container {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  /* center and flex wrap in row direction */
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: center;
}

a:hover {
  transition: all 0.3s ease;
  transform: scale(1.2);
}

.app-title {
  font-size: 10rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: .5rem;
}

.app-subtitle {
  font-size: 2.2rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.button-container {
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.custom-btn {
  font-size: 1.8rem;
  font-weight: 500;
  padding: 1rem;
  border-radius: 2rem;
  flex-basis: 80%;
  margin-bottom: 1rem;
}

.custom-btn-primary {
  background: rgba(23, 26, 42, 0.8);
  color: white;
}

.custom-btn-secondary {
  background: rgba(255, 255, 255, 0.65);
  color: var(--primary-color);
}