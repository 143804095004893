
.cards {
    box-shadow: 0 1rem 1.75rem 0 rgb(33 40 50 / 15%);
    height: 12rem;
    border-radius: .8rem;
    transition: 0.3s;
    width: 30rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    border-left-style: solid;
    border-left-width: 0.5rem;
    overflow: hidden;
}
.cards:hover{
    transform: rotate(-10deg);
    background-color: lightgrey;
}
.card-body>p{
    margin-bottom: .5rem;
    text-transform: capitalize;
}
.cards h1 {
    font-size: 4rem;
    font-weight: 700;
}
.client-card {
    border-left-color: #927676;
}
.investments-card {
    border-left-color: #00ac69;
}