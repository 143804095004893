#client-page {
    color: #1a202c;
    text-transform: capitalize;
    overflow-x: hidden;
}

.client-info {
    margin: 2rem 0;
}

.profile-card {
    box-shadow: 4px 4px 5px 5px rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .08);
}

.profile-card {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    width: 100%;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: .5rem;
}

.profile-card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.btn {
    text-transform: capitalize;
}

.btn:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.3s;
}


.totals-column {
    padding: 15px;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    /* center and flex wrap in row direction */
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: center;
}

/* totals card */
.totals {
    box-shadow: 0 0.5rem 1.75rem 0 rgb(33 40 50 / 15%);
    height: 10rem;
    border-radius: .8rem;
    transition: 0.3s;
    width: 25rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    border-left-style: solid;
    border-left-width: 0.5rem;
}

.totals:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
}

.totals h1 {
    font-size: xx-large;
    font-weight: 700;
}

.red-card {
    border-left-color: #921111;
}

.principal-card {
    border-left-color: #6900c7;
}

.interest-card {
    border-left-color: #00ac69;
}

.investment-value-card {
    border-left-color: #0061f2;
}

/* INVESTMENTS SECTION */
.investments-block {
    border-radius: 1rem;
    transition: 0.3s;
    border-left-style: solid;
    border-left-width: 0.5rem;
    border-left-color: #0061f2;
    border-top-style: solid;
    border-top-width: 0.1rem;
    border-top-color: #0061f2;
    margin: 2rem 0;
    padding: 1.2rem;
    overflow-x: scroll;
    box-shadow: 4px 4px 5px 5px rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .08);
}
