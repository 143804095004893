*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;  /*makes 1rem = 10px */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  text-align: center;
  color: black;
}

a:active,
a:link,
a:visited {
    text-decoration: none;
}

